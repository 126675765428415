import { Icons } from 'helpers/icons';

const FOOTER_SOCIALS = [
    {
        name: 'Twitter',
        href: 'https://x.com/YellowCom_News',
        icon: () => <Icons name="twitter" />,
        target: '_blank',
    },
    {
        name: 'Telegram',
        href: 'https://t.me/YellowCom_news',
        icon: () => <Icons name="telegram" />,
        target: '_blank',
    },
    {
        name: 'Youtube',
        href: 'https://www.youtube.com/channel/UC2u2FXKKyIFsNBr_MlpCMfA/',
        icon: () => <Icons name="youtube" />,
        target: '_blank',
    },
    {
        name: 'Linkedin',
        href: 'https://www.linkedin.com/company/yellow-com/',
        icon: () => <Icons name="linkedin" />,
        target: '_blank',
    },
    {
        name: 'Discord',
        href: 'https://discord.com/invite/DKBjCg6rmT',
        icon: () => <Icons name="discord" />,
        target: '_blank',
    },
    {
        name: 'Medium',
        href: 'https://medium.com/yellow-blog',
        icon: () => <Icons name="medium" />,
        target: '_blank',
    },
    {
        name: 'Facebook',
        href: 'https://www.facebook.com/YellowComNews',
        icon: () => <Icons name="facebook" />,
        target: '_blank',
    },
    {
        name: 'Reddit',
        href: 'https://www.reddit.com/r/YellowDeFi/',
        icon: () => <Icons name="reddit" />,
        target: '_blank',
    },
];

const FOOTER_NAVIGATION = [
    {
        name: 'footer.navigation.yellow',
        fallback: 'Yellow.com',
        submenu: [
            {
                name: 'footer.navigation.yellow.submenu.about',
                fallback: 'About Us',
                href: '/about_us',
                target: '_blank',
            },
            // {
            //   name: "footer.navigation.yellow.submenu.team",
            //   href: '/',
            //   target: '_blank',
            // },
            {
                name: 'footer.navigation.yellow.submenu.contact',
                fallback: 'Contact Us',
                href: '/',
                target: '_blank',
            },
            {
                name: 'footer.navigation.yellow.submenu.openPositions',
                fallback: 'Open Positions',
                href: 'https://yellow.com/news/yellowcom-is-looking-for-a-content-editor',
                target: '_blank',
            },
            {
                name: 'footer.navigation.yellow.submenu.writeForUs',
                fallback: 'Write for Us',
                href: '/',
                target: '_blank',
            },
            {
                name: 'footer.navigation.yellow.submenu.complains',
                fallback: 'Complains and Corrections',
                href: '/',
                target: '_blank',
            },
        ],
    },
    {
        name: 'footer.navigation.legal',
        fallback: 'Legal',
        submenu: [
            {
                name: 'footer.navigation.legal.submenu.terms',
                fallback: 'Terms of Service',
                href: '/terms_of_service',
                target: '_blank',
            },
            {
                name: 'footer.navigation.legal.submenu.privacy',
                fallback: 'Privacy Policy',
                href: '/privacy_policy',
                target: '_blank',
            },
            {
                name: 'footer.navigation.legal.submenu.cookie',
                fallback: 'Cookie Policy',
                href: '/cookie_policy',
                target: '_blank',
            },
        ],
    },
    {
        name: 'footer.navigation.company',
        fallback: 'Company',
        submenu: [
            {
                name: 'footer.navigation.company.submenu.yellowNetwork',
                fallback: 'Yellow Network',
                href: 'https://www.yellow.org/',
                target: '_blank',
            },
            {
                name: 'footer.navigation.company.submenu.yellowCapital',
                fallback: 'Yellow Capital',
                href: 'https://www.yellowcapital.com/',
                target: '_blank',
            },
            {
                name: 'footer.navigation.company.submenu.openware',
                fallback: 'Openware',
                href: 'https://www.openware.com/',
                target: '_blank',
            },
            {
                name: 'footer.navigation.company.submenu.yellowCoworking',
                fallback: 'Yellow Coworking',
                href: 'https://www.yellowincubator.com/coworking',
                target: '_blank',
            },
            {
                name: 'footer.navigation.company.submenu.foundation',
                fallback: 'Foundation',
                href: 'https://www.yellowincubator.com/foundation',
                target: '_blank',
            },
        ],
    },
    {
        name: 'footer.navigation.exchange',
        fallback: 'Exchange',
        submenu: [
            {
                name: 'footer.navigation.exchange.submenu.howItWorks',
                fallback: 'How it Works',
                href: 'https://docs.yellow.org/about/the-solution',
                target: '_blank',
            },
            {
                name: 'footer.navigation.exchange.submenu.tokens',
                fallback: 'Tokens',
                href: '/join_yellow_network',
                target: '_blank',
            },
            {
                name: 'footer.navigation.exchange.submenu.yellowToken',
                fallback: 'Yellow Token',
                href: '/discover_yellow_token',
                target: '_blank',
            },
        ],
    },
];

export const FOOTER_OPTIONS = {
    navigations: FOOTER_NAVIGATION,
    socials: FOOTER_SOCIALS,
    upIcon: () => <Icons name="upIcon" />,
    downIcon: () => <Icons name="downIcon" />,
    socialIconNumDisplay: 8,
};
