import { FC } from 'react'

interface PrevIconProps {
  classNames?: string
  disabled: boolean
  fill?: string
}

export const PrevIcon: FC<PrevIconProps> = ({ classNames = 'm-auto', disabled, fill = '#090909' }) => (
  <svg className={classNames} width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.20162 0.261289L6.73632 0.790717C6.91209 0.974183 7 1.18846 7 1.43306C7 1.6827 6.91209 1.89443 6.73632 2.0686L3.27182 5.49998L6.73622 8.93133C6.91199 9.10547 6.9999 9.31725 6.9999 9.56679C6.9999 9.81151 6.91199 10.0257 6.73622 10.2092L6.20152 10.7317C6.02104 10.9106 5.80475 11 5.55285 11C5.29626 11 5.08242 10.9105 4.91137 10.7317L0.270843 6.13547C0.0903303 5.96595 2.34498e-07 5.75424 2.45863e-07 5.5C2.57015e-07 5.25053 0.0903056 5.03633 0.270843 4.85761L4.91137 0.261313C5.08724 0.0871457 5.30106 -7.26169e-08 5.55285 -6.18546e-08C5.80005 -2.48468e-05 6.01622 0.087121 6.20162 0.261289Z"
      fill={fill}
      fillOpacity={`${disabled ? '0.24' : ''}`}
    />
  </svg>
)

export const MobilePrevIcon: FC<PrevIconProps> = ({ fill = '#272727' }) => (
  <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.2929 9.70711L0.292894 5.70711C-0.0976312 5.31658 -0.0976312 4.68342 0.292894 4.29289L4.29289 0.292894C4.68342 -0.0976312 5.31658 -0.0976312 5.70711 0.292894C6.09763 0.683417 6.09763 1.31658 5.70711 1.70711L3.41421 4L15 4C15.5523 4 16 4.44771 16 5C16 5.55228 15.5523 6 15 6L3.41421 6L5.70711 8.29289C6.09763 8.68342 6.09763 9.31658 5.70711 9.70711Z"
      fill={fill}
    />
  </svg>
)

interface NextIconProps {
  classNames?: string
  disabled?: boolean
  fill?: string
}

export const NextIcon: FC<NextIconProps> = ({ classNames = 'm-auto', disabled = false, fill = '#090909' }) => (
  <svg className={classNames} width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.798375 0.261289L0.263678 0.790717C0.0879091 0.974183 -4.38599e-07 1.18846 -4.27665e-07 1.43306C-4.16505e-07 1.6827 0.0879092 1.89443 0.263678 2.0686L3.72818 5.49998L0.263778 8.93133C0.0880093 9.10547 9.99292e-05 9.31725 9.99403e-05 9.56679C9.99512e-05 9.81151 0.0880094 10.0257 0.263778 10.2092L0.798476 10.7317C0.978963 10.9106 1.19525 11 1.44715 11C1.70374 11 1.91758 10.9105 2.08863 10.7317L6.72916 6.13547C6.90967 5.96595 7 5.75424 7 5.5C7 5.25053 6.90969 5.03633 6.72916 4.85761L2.08863 0.261313C1.91276 0.0871457 1.69894 -7.26169e-08 1.44715 -6.18546e-08C1.19995 -2.48468e-05 0.983782 0.087121 0.798375 0.261289Z"
      fill={fill}
      fillOpacity={`${disabled ? '0.24' : ''}`}
    />
  </svg>
)

export const MobileNextIcon: FC<NextIconProps> = ({ fill = '#272727' }) => (
  <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893L15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.7071 9.70711C11.3166 10.0976 10.6834 10.0976 10.2929 9.70711C9.90238 9.31658 9.90238 8.68342 10.2929 8.29289L12.5858 6H1C0.447716 6 -2.41411e-08 5.55228 0 5C2.41411e-08 4.44772 0.447716 4 1 4H12.5858L10.2929 1.70711C9.90238 1.31658 9.90238 0.683418 10.2929 0.292893Z"
      fill={fill}
    />
  </svg>
)

export const CaretUp: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.715 10.129L13.1374 10.7124C12.9373 10.9041 12.7035 11 12.4367 11C12.1643 11 11.9333 10.9041 11.7433 10.7124L8.00003 6.9329L4.25673 10.7122C4.06676 10.904 3.83573 10.9999 3.5635 10.9999C3.29653 10.9999 3.06283 10.904 2.86265 10.7122L2.29268 10.1289C2.09752 9.93204 2 9.69609 2 9.42129C2 9.14138 2.09763 8.90809 2.29265 8.72149L7.30676 3.6591C7.49169 3.46218 7.72265 3.36364 8 3.36364C8.27215 3.36364 8.50583 3.46215 8.70079 3.6591L13.7149 8.7215C13.9049 8.91335 14 9.14661 14 9.42129C14 9.69097 13.905 9.92678 13.715 10.129Z" />
  </svg>
)

export const CaretDown: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.28504 5.87096L2.8626 5.28765C3.06275 5.0959 3.2965 5 3.56334 5C3.83567 5 4.06665 5.0959 4.25665 5.28765L7.99997 9.0671L11.7433 5.28776C11.9332 5.09601 12.1643 5.00011 12.4365 5.00011C12.7035 5.00011 12.9372 5.09601 13.1373 5.28776L13.7073 5.87106C13.9025 6.06796 14 6.30391 14 6.57871C14 6.85862 13.9024 7.09191 13.7073 7.27851L8.69324 12.3409C8.50831 12.5378 8.27735 12.6364 8 12.6364C7.72785 12.6364 7.49417 12.5378 7.29921 12.3409L2.28507 7.27851C2.09507 7.08665 2 6.85339 2 6.57871C1.99997 6.30903 2.09504 6.07322 2.28504 5.87096Z"
      fill="#272727"
    />
  </svg>
)
