import { NextIcon } from 'assets/images/Arrow'
import Link from 'next/link';
import React, { FC } from 'react'

interface PoweredByProps {
  icon: React.ReactNode
  poweredBy: string
  appName: string
  appUrl: string
  description: React.ReactNode
}

export const PoweredBy: FC<PoweredByProps> = ({ icon, poweredBy, appName, description, appUrl }) => {
  return (
    <div className="flex flex-col justify-center max-w-[250px] py-4 mr-[100px]">
      <Link
        href={appUrl}
        className="group relative inline-flex items-center py-2 rounded duration-100 hover:bg-neutral-control-color-20"
        rel="noreferrer noopener"
        target="_blank"
      >
        {icon}
        <div className="flex flex-col ml-3">
          <span className="text-neutral-control-layer-color-40">{poweredBy}</span>
          <span className="text-neutral-control-layer-color-60 font-metro-bold">{appName}</span>
        </div>
        <div className="absolute right-4 duration-200 opacity-0 -translate-x-full group-hover:opacity-100 group-hover:translate-x-0">
          <NextIcon fill="currentColor" disabled={false} />
        </div>
      </Link>
      <p className="font-metro-semibold text-neutral-control-layer-color-50 text-sm mb-3 py-2">{description}</p>
    </div>
  )
}
